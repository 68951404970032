import Topbar from './components/topbar/Topbar';
import Intro from './components/intro/Intro';
import Contact from './components/contact/Contact';
import Menu from './components/menu/Menu';
import { useState } from 'react';
import "./App.scss"
import { ThemeProvider } from 'styled-components';
import { createTheme } from '@mui/system';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Projects from './pages/Projects';

const theme = createTheme({

});



function App() {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <ThemeProvider theme={theme}>
      <div className='app'>
        <BrowserRouter>
        <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <Routes>
            <Route path="/" element={
            <div className="sections">
              <Intro />
              <Contact />
            </div>
            } />
            <Route path="projects" element={<Projects />} />

          </Routes>
        </BrowserRouter>

      </div>
    </ThemeProvider>
  );
}

export default App;

