import React from 'react'
import { Link } from "react-router-dom";

import './menu.scss'

export default function menu({ menuOpen, setMenuOpen }) {
    return (
        <div className={"menu " + (menuOpen && "active")}>
            <ul>
                <li onClick={() => setMenuOpen(false)}>
                    <Link to="/">home</Link>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <Link to="/projects">recent projects </Link>
                </li>
                <li onClick={() => setMenuOpen(false)} >
                    <a href="/#contact">contact</a>
                </li>
            </ul>
        </div>
    )
}
