import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgLI0RTXDsheJ4cuVhdpL9D3nqA0Pt7UQ",
  authDomain: "danielsystems-62d61.firebaseapp.com",
  projectId: "danielsystems-62d61",
  storageBucket: "danielsystems-62d61.appspot.com",
  messagingSenderId: "334978171420",
  appId: "1:334978171420:web:715b45d513a28e9ab595d9",
  measurementId: "G-JCNTZH0MLM"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>

  ,
  document.getElementById('root')
);
