
import './topbar.scss';
import { default as Logo } from '../../assets/images/DFLogoWhite.svg'
import { Link } from "react-router-dom";





export default function Topbar({ menuOpen, setMenuOpen }) {
    return (
        <div className={"topbar " + (menuOpen && "active")}>
            <div className="wrapper">
                <div className="left">
                    <div className="box">
                        <a href="#intro" id="topBarLogo"><img className="DFLogo" src={Logo}></img> </a>
                    </div>
                </div>
                <div className="right">
                    <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
                        <span className="line1"></span>
                        <span className="line2"></span>
                        <span className="line3"></span>
                    </div>
                    <div className="horizontalMenu">
                        <ol type="i">
                            <li> <Link to="/">home page </Link> </li>
                            <li> <Link to="/projects"> recent projects </Link></li>
                            <li><a href="/#contact">reach out</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}
