import "./contact.scss";
import { Container } from "@mui/material";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from '@mui/material/Grid';
import GitHubIcon from '@mui/icons-material/GitHub';



const MyButton = styled(Button)({
    color: 'white',
    height: 48,
    margin: '30px',
    padding: '0 30px',
    border: '#E5383B 2px solid',
});

const GridMaxHeight = styled(Grid)({
    height: '100%',
});

export default function Contact() {
    return (
        <div className="about" id="contact">
            <Container fixed>
                <div className="wrapper">
                    <div className="contactSection">
                        <h1 className="title"> reach out </h1>
                        <p className="contactPara">
                            If you have any questions or just want to say hi - Feel free to use any of the links below to get in contact with me.
                        </p>
                        <GridMaxHeight container 
                            direction="column"
                            alignItems="center"
                            justifyContent="center" spacing={6}>
                            <Grid item lg={4} md={12} xs={12} sm={12}>
                                <MyButton href="mailto:daniel@daniel.systems"> <ContactMailIcon style={{ marginRight: '25px' }} />  E-Mail </MyButton>
                            </Grid>
                            <Grid item lg={4} md={12} xs={12} sm={12} >
                                <MyButton href="https://www.linkedin.com/in/danielfagbuyi/"> <LinkedInIcon style={{ marginRight: '25px' }} />  LinkedIn  </MyButton>
                            </Grid>
                            <Grid item lg={4} md={12} xs={12} sm={12} >
                                <MyButton href="https://github.com/lions-dan/"> <GitHubIcon style={{ marginRight: '25px' }} />  Github </MyButton>
                            </Grid>
                        </GridMaxHeight>
                    </div>
                </div>
            </Container>
        </div>
    )
}
