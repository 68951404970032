import "./intro.scss"
import gsap from "gsap";
import { useRef, useEffect, useReducer, useLayoutEffect } from "react";
import { Button, Container, duration } from "@mui/material";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import { TextPlugin } from "gsap/all";
import { createRef } from "react";

const MyButton = styled(Button)({
    color: 'white',
    height: 48,
    margin: '30px',
    padding: '0 30px',
    alignSelf: 'flex-start',
    border: 'white 2px solid'
});

const GridMaxHeight = styled(Grid)({
    height: '100%',
});
gsap.registerPlugin(TextPlugin);



const initialState = { spanRefs: [], lineRefs: [], arrLength: 14, commandText: ["cd ./daniel", "print 'LEARN_ABOUTME.md'? (Y/N)", '# ABOUT ME #', "I am full-stack developer from DC, but currently based in Arizona.", "I create websites, applications, and do block chain development." , "I am fully employed but open to discuss additional opportunties.", "#Tech-Stack#", "####[Frontend]####", "React | Javascript , HTML , CSS", "####[Backend]#####" ,"Java | C# , Python, Solidity", "[Additonal] Azure, Firebase" , "Feel free to contact me using any of the links below.", "=====END_ABOUT_ME=====" ]};

function reducer(state, action) {
    switch (action.type) {
        case 'load':
            return {
                spanRefs: Array(state.arrLength)
                    .fill()
                    .map((_, i) => state.spanRefs[i] || createRef()),
                lineRefs: Array(state.arrLength)
                    .fill()
                    .map((_, i) => state.lineRefs[i] || createRef()), arrLength: state.arrLength, commandText: initialState.commandText
            }
        case 'startAnimation':
            printConsole(state.spanRefs, state.lineRefs, state.commandText, action);
            return { ...state }
        case 'displayAboutMe':
            printConsole(state.spanRefs, state.lineRefs, state.commandText, action, 2, 14);
            return { ...state }
        case 'invalidInput':
            printConsole(state.spanRefs, state.lineRefs, state.commandText, action, 14, 14);
        default:
            return state
    }
}

// Function to Print to Console
//span references   //commandText //input handle //action
function printConsole(span_ref, line_ref, commandText, action, start = 0, end = 2) {
    var tl = gsap.timeline();
    var nextLine = 1;
    switch (action.type) {
        case 'startAnimation':
            for (let i = start; i < end; i++) {
                tl.to(line_ref[i].current, { display: 'block', duration: .1, ease: "power1.inOut" })
                tl.to(span_ref[i].current, { text: { value: commandText[i] }, duration: 1.5, ease: "power1.inOut" })
                if (nextLine === line_ref.length) {
                    return
                }
                tl.to(line_ref[nextLine].current, { display: 'block', duration: .1, ease: "power1.inOut" })
                nextLine++;
            }
            if (span_ref[2].current.children.length < 1) {
                var newElement = document.createElement('input');
                newElement.setAttribute('maxLength', '1');
                newElement.addEventListener('input', action.eventHandle);
                span_ref[2].current.appendChild(newElement);
            }
            break;
        case 'displayAboutMe':
            for (let i = start; i < end; i++) {
                tl.to(line_ref[i].current, { display: 'block', duration: .1, ease: "power1.inOut" })
                tl.to(span_ref[i].current, { text: { value: commandText[i] }, duration: 1.5, ease: "power1.inOut" })
                if (nextLine === line_ref.length) {
                    return
                }
                tl.to(line_ref[nextLine].current, { display: 'block', duration: .1, ease: "power1.inOut" })
                nextLine++;
            }
            break;
        case 'invalidInput':
            for (let i = start-1; i < end; i++) {
                tl.to(line_ref[i].current, { display: 'block', duration: .1, ease: "power1.inOut" })
                tl.to(span_ref[i].current, { text: { value: commandText[i] }, duration: 1.5, ease: "power1.inOut" })
                if (nextLine === line_ref.length) {
                    return
                }
                tl.to(line_ref[nextLine].current, { display: 'block', duration: .1, ease: "power1.inOut" })
                nextLine++;
            }
            break;

    }

}

function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return today = mm + '/' + dd + '/' + yyyy;
}
export default function Intro() {
    const [state, dispatch] = useReducer(reducer, initialState);

    const eventHandler = (e) => {
        if (e.target.value === 'Y' || e.target.value === 'y') {
            dispatch({ type: 'displayAboutMe'})
        }else{
            dispatch({ type: 'invalidInput'})
        }
    }



    useLayoutEffect(() => {
        dispatch({ type: 'load' })
    }, []);

    useEffect(() => {
        if (state.spanRefs.length === state.arrLength && state.lineRefs.length == state.arrLength) {
            dispatch({ type: 'startAnimation', eventHandle: eventHandler })
        }
    }, [state.spanRefs, state.lineRefs, state.arrLength]);


    return (
        <Container maxWidth={false} disableGutters={true}>
            <GridMaxHeight container spacing={1}>
                <Grid item lg={4} md={12} xs={12} sm={12}>
                    <div id="intro">
                        <div className='introText'>
                            <h4> Hi. </h4>
                            <h1> I'm Daniel. </h1>
                            <h2>  programmer. </h2>
                            <p className="subDesc">  📍 Phoenix, Arizona 📍  </p>
                            <p className="subDesc"> 🎓 University of Arizona Alumni 🎓  </p>
                            <MyButton variant="outlined" href="#contact">contact </MyButton>
                        </div>
                    </div>
                </Grid>
                <Grid item  lg={8} md={12} xs={12} sm={12} >
                    <div className='introText consoleSide'>
                        <pre id="Tbd">
                            daniel.systems ~$ login date:  <span id="date"> {getDate()} </span>
                            <div id="Cmd">
                                {Array(state.arrLength).fill().map((el, i) => (
                                    <p className="console_prefix" ref={state.lineRefs[i]} key={i}><span>daniel@daniel.systems~$</span> <span key={i} ref={state.spanRefs[i]} ></span></p>
                                ))}
                            </div>
                        </pre>
                    </div>
                </Grid>
            </GridMaxHeight>
        </Container>
    )
}
