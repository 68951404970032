import * as THREE from 'three'
import { useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Image, Text, Environment } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import getUuid from 'uuid-by-string'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import MajorMono from "../../assets/fonts/MajorMono.json"
import SpaceMono from "../../assets/fonts/SpaceMonoBold.json"

const GOLDENRATIO = 1.61803398875

export default function MainCanvas({ images }) {
  return (
    <Canvas style={{ width: '100vw', height: '100vh' }} gl={{ alpha: false }} dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 15] }}>
      <color attach="background" args={['#000000']} />
      <fog attach="fog" args={['#222220', 0, 15]} />
      <Environment preset="city" />
      <group position={[0, -0.5, 0]}>
        <Frames images={images} />
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            resolution={2048}
            mixBlur={1}
            mixStrength={60}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.0}
            color="#151515"
            metalness={0.5}
          />
        </mesh>
      </group>
    </Canvas>
  )
}

function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    state.camera.position.lerp(p, THREE.MathUtils.damp(0, 1, 3, dt))
    state.camera.quaternion.slerp(q, THREE.MathUtils.damp(0, 1, 3, dt))
  })
  return (
    <group
      ref={ref}
      onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

function Frame({ url, c = new THREE.Color(), link, description, technologies, ...props }) {


  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const image = useRef()
  const frame = useRef()
  const name = getUuid(url)

  useCursor(hovered)
  useFrame((state) => {
    image.current.material.zoom = 1.5 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    image.current.scale.x = THREE.MathUtils.lerp(image.current.scale.x, 0.85 * (hovered ? 0.85 : 1), 0.1)
    image.current.scale.y = THREE.MathUtils.lerp(image.current.scale.y, 0.9 * (hovered ? 0.905 : 1), 0.1)
    frame.current.material.color.lerp(c.set(hovered ? 'black' : '#121212').convertSRGBToLinear(), 0.1)
  })
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image raycast={() => null} ref={image} position={[0, 0, 2.0]} url={url} />
      </mesh>
      <Text   font='/MajorMonoDisplay-Regular.ttf' color={'white'} onClick={(e) => { window.open(link) }}
        maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, 1.2, 0]} fontSize={0.025} outlineColor={'#660708'} outlineWidth={0.015} strokeColor={'white'} strokeWidth={0.010} strokeOpacity={100}>
        {link}
      </Text>
      <Text textAlign='left' color={'black'} font='/SpaceMono-BoldItalic.ttf' maxWidth={0.7} anchorX="left" anchorY="top" position={[0.54, 1.1, 0]} fontSize={0.025}  outlineColor={'white'} outlineWidth={0.010}>
        {description}
      </Text>
      <Text textAlign='left' color={'black'} font='/SpaceMono-BoldItalic.ttf' maxWidth={0.3} anchorX="left" anchorY="top" position={[0.54, 1.0, 0]} fontSize={0.025}  outlineColor={'#4ecdc4'} outlineWidth={0.010}>
        {technologies}
      </Text>
    </group>
  )
}
