import React, { Suspense, useState } from 'react'
import MainCanvas  from '../components/frames/Frames';


function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <a href="https://github.com/lions-dan/" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px', fontFamily: "'Titillium Web', sans-serif" }}>
        Recent Projects
        <br />
        Last Updated  1/19/2022
      </a>
    </div>

  )
}

const pexel = (id) => `/${id}.jpg`
const images = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0], url: pexel(1) , link: 'https://theentity.io/', description: 'Launchpad for Decentralized Applications and NFT/Crypto Assets.', technologies: '•React •Javascript •ThreeJS •SCSS'},
  // Back
  { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(2) , link: 'https://monstersquadnft.com/', description:  'Frontend Website built for Monster Squad NFT w/ Web3 components', technologies:'•React •SCSS •Ether.js •Javascript'},
  { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(3) , link: 'https://github.com/lions-dan/' , description: 'View some of my oldest repository on Github', technologies: '•Java  •C#  •React •Python'},
  // Left
  { position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: pexel(4), link: 'https://daniel.systems/', description: 'Portfolio website built for personal use', technologies: '•React •SCSS •Javascript'},
  //{ position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: pexel(325185) },
  //{ position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: pexel(358574) },
  // Right
  //{ position: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: pexel(227675) },
  //{ position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: pexel(911738) },
  //{ position: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738986) }
]


function Projects() {

  return (
    <div>
      <Suspense fallback={null}>
        <MainCanvas images={images} />
      </Suspense>
      <Overlay />
    </div>
  )
}

export default Projects;
